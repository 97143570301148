<template>
  <GumSkeletonLine v-if="loading" />
  <GumPhoneInputInternal
    v-else
    :callingCodes="callingCodes"
    :value="props.value || {}"
    @update:value="(val) => emit('update:value', val)"
  />
</template>

<script setup lang="ts">
import GumSkeletonLine from '../../GumSkeletonLine.vue'
import GumPhoneInputInternal from './GumPhoneInputInternal.vue'
import { getCallingCodes } from './getCallingCodes.gql'
import type { GumPhoneInputValue } from './types'

const props = defineProps<{
  value: GumPhoneInputValue | undefined
}>()

const emit = defineEmits<{
  (e: 'update:value', value: GumPhoneInputValue): void
}>()

const { loading, callingCodes } = getCallingCodes()
</script>
