<template>
  <div class="gum-phone-select">
    <GumSelect
      :options="options"
      v-model:value="selectedCode"
      :dropdownMatchSelectWidth="false"
      class="gum-phone-select-code"
    />
    <GumTextInput
      v-model:value="selectedNumber"
      class="gum-phone-select-number"
      @keypress="allowCharactersOnly($event)"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import GumSelect from '../GumSelect.vue'
import { GumTextInput } from '../components'
import { type CallingCode } from './getCallingCodes.gql'
import type { GumPhoneInputValue } from './types'

const props = defineProps<{
  value: GumPhoneInputValue
  callingCodes: CallingCode[]
}>()

const emit = defineEmits<{
  (e: 'update:value', value: GumPhoneInputValue): void
}>()

const selectedCode = ref<string | undefined>(props.value.code)
const selectedNumber = ref<string | undefined>(props.value.number)

watch([selectedCode, selectedNumber], () => {
  emit('update:value', { code: selectedCode.value, number: selectedNumber.value })
})

const allowCharactersOnly = (e: KeyboardEvent) => {
  // TODO: migrate away from deprecated charCode
  if (e.charCode === 0 || /[0-9- ()]/.test(e.key)) {
    return true
  } else {
    e.preventDefault()
  }
}

const options = computed(() =>
  props.callingCodes.map((item) => ({
    value: item.callingCode,
    label: item.callingCode,
    labelForDropdown: `${item.countryName} (${item.callingCode})`
  }))
)
</script>

<style lang="less" scoped>
.gum-phone-select {
  display: grid;
  grid-template-columns: 100px minmax(0, 1fr);
}

.gum-phone-select-code:deep(.ant-select-selector) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

:deep(.gum-phone-select-number) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left-width: 0;
}
</style>
